import styled from '@emotion/styled';
import { BANNER_OPACITY, BODY_COLOR_OPACITY } from '../../../utils/common';

export const MiddleBodyVideoBackground = styled.div`
  background: ${(props) =>
    !props?.propsBackground?.content[0]?.url && `none, ${props?.themeBackground}`};
  background-size: cover;
  background-position: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0; /* Set the z-index behind other content */
  }

  /* Add a linear gradient overlay */
  &::after {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: ${BANNER_OPACITY};
  }
  @media screen and (min-width: 768px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }
  @media screen and (max-width: 767px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }
  & > video {
    position: absolute;
    height: 100%;
  }
  & > img {
    position: absolute;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

export const MiddleBodyParent = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  &.bg_type_solid {
    background: ${(props) => props?.themeBackground};
  }
  .bg_type_solid {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${BANNER_OPACITY};
    z-index: 1;
  }
`;
export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.625rem;
  width: 100;
  justify-content: center;
  z-index: 2;
  @media screen and (min-width: 768px) {
    padding: 6rem 8.25rem;
  }
  @media screen and (max-width: 767px) {
    padding: 2rem 1.5rem;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
  gap: 1.5rem;
  & > h1 {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    color: ${(props) => props?.textStyles?.heading?.color};
    font-family: ${(props) => props?.textStyles?.heading?.fontFamily || 'Arial'};
    text-align: ${(props) => props?.textStyles?.heading?.textAlign || 'center'};
    width: 100%;
    justify-content: center;
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & > p {
    display: flex;
    flex-wrap: wrap;
    font-style: normal;
    margin: 0;
    color: ${(props) => props?.textStyles?.body?.color};
    text-align: ${(props) => props?.textStyles?.body?.textAlign || 'center'};
    font-family: ${(props) => props?.textStyles?.body?.fontFamily || 'Arial'};
    opacity: ${BODY_COLOR_OPACITY};
    width: 100%;
    justify-content: center;
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }

  @media screen and (min-width: 768px) {
    & > h1 {
      font-size: ${(props) => props?.textStyles?.heading?.fontSize || '3.25rem'};
      font-weight: ${(props) => props?.textStyles?.heading?.fontWeight || '500'};
      line-height: 3.875rem;
    }
    & > p {
      font-size: ${(props) => props?.textStyles?.body?.fontSize || '1.25rem'};
      font-weight: ${(props) => props?.textStyles?.body?.fontWeight || '400'};
      line-height: 1.75rem;
    }
  }
  @media screen and (max-width: 767px) {
    & > h1 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: normal;
      width: 100%;
    }
    & > p {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: normal;
      width: 100%;
    }
  }
`;
export const BottomSection = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  z-index: 2;
  justify-content: center;
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    justify-content: center;
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
    flex-direction: column;
    width: 100%;
    button {
      width: 100%;
    }
  }
`;
